<template>
  <DeleteEventsTable/>
</template>

<script>
import DeleteEventsTable from "@/components/tables/DeleteEventsTable.vue";

export default {
  name: 'DeleteEventsView',
  components: {DeleteEventsTable}
}
</script>