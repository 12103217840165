<template>
  <div>
    <Card>
      <template #title>{{ title ?? 'Удаленные события' }}</template>
      <template #content>
        <AbstractTable code="deleteEvents" ref="dataTable" :default-data="defaultData" display-filter="menu">
          <template #additionalButtons>
            <Button
              icon="pi pi-refresh"
              class="p-button-rounded p-button-outlined"
              @click="$refs.dataTable.getData()"
            ></Button>
          </template>
          <template #type="row">
            {{ $filters.translate($store, 'eventType', row.data.type) }}
          </template>
          <template #applicationUuid="row">
            <ValueChip
              :value="row.data.applicationUuid"
              toast-template='Идентификатор заявки "{v}" скопирован'
            />
          </template>
          <template #cbUuid="row">
            <ValueChip
              :value="row.data.cbUuid"
              toast-template='ЦБ УИД "{v}" скопирован'
            />
          </template>
        </AbstractTable>
      </template>
    </Card>
  </div>
</template>

<script>
import AbstractTable from "@/components/AbstractTable.vue";
import ValueChip from "@/components/Common/ValueChip.vue";

export default {
  name: 'DeleteEventsTable',
  components: {AbstractTable, ValueChip},
  props: {
    title: {
      type: String,
      default: () => null
    },
    defaultData: {
      type: Object,
      default: () => null
    }
  }
}
</script>